import api from './api';
import { request } from './request'

// 用户登录
function userLogin(userName, password) {
    return request({
        url: api.login.login,
        method: 'post',
        data: {
            userName,
            password
        }
    })
}

// 退出登录
function userLogout() {
    return request({
        url: api.login.logout,
        method: 'post'
    })
}

export {
    userLogin,
    userLogout
}