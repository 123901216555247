<template>
    <div id="login">
        <div class="logo-and-title">
            <img class="logo"
                 src="@/assets/images/logo.png"
                 alt="" />

            <div class="title">
                <span>智能零售管理后台</span>
            </div>
        </div>

        <div class="tips">
            <span>管理员后台</span>
        </div>

        <a-form :form="form"
                @submit="handleSubmit">
            <a-form-item :validate-status="userNameError() ? 'error' : ''"
                         :help="userNameError() || ''">

                <a-input class="input-item"
                         size="large"
                         v-decorator="validate.username"
                         placeholder="账号">

                    <a-icon slot="prefix"
                            type="user"
                            style="color:rgba(0,0,0,.25)" />

                </a-input>

            </a-form-item>

            <a-form-item :validate-status="passwordError() ? 'error' : ''"
                         :help="passwordError() || ''">

                <a-input class="input-item"
                         size="large"
                         v-decorator="validate.password"
                         type="password"
                         placeholder="密码">

                    <a-icon slot="prefix"
                            type="lock"
                            style="color:rgba(0,0,0,.25)" />

                </a-input>

            </a-form-item>

            <a-form-item>
                <a-checkbox class="remember-login" @change="onChange">
                    记住登录
                </a-checkbox>
            </a-form-item>

            <a-form-item>
                <a-button class="login-btn"
                          size="large"
                          type="primary"
                          html-type="submit"
                          :disabled="hasErrors(form.getFieldsError())">
                    登录
                </a-button>
            </a-form-item>
        </a-form>
    </div>
</template>

<script>
import { userLogin } from 'network/login';

import md5 from 'blueimp-md5'

import { setCookie } from 'common/utils';

import { mapActions } from 'vuex'

// 错误
function hasErrors (fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

export default {
    name: 'Login', // 登录
    data () {
        return {
            remember: 0,

            hasErrors,
            form: this.$form.createForm(this, { name: 'login' }),

            validate: {
                username: [
                    'username',
                    { rules: [{ required: true, message: '请输入账号！' }] },
                ],
                password: [
                    'password',
                    { rules: [{ required: true, message: '请输入密码！' }] },
                ]
            }
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.form.validateFields();
        });
    },
    methods: {
        // ---------------事件处理函数--------------
        ...mapActions(['saveUserInfoAsync']),

        onChange() {
            this.remember = this.remember === 0 ? 1 : 0;
        },
        userNameError () {
            const { getFieldError, isFieldTouched } = this.form;
            return isFieldTouched('username') && getFieldError('username');
        },
        passwordError () {
            const { getFieldError, isFieldTouched } = this.form;
            return isFieldTouched('password') && getFieldError('password');
        },
        handleSubmit (e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if (!err) {
                    let userName = values.username.trim();
                    let password = md5(values.password.trim());

                    this._userLogin(userName, password);
                }
            });
        },

        // -----------------网络请求--------------
        // 登录
        _userLogin (userName, password) {
            userLogin(userName, password).then(res => {
                const data = res.data;

                const userInfo = {
                    nickname: data.user.realname,
                    username: data.user.username
                }

                this.saveUserInfoAsync(userInfo)

                if (this.remember) {
                    setCookie('token', data.token)
                } else {
                    sessionStorage.setItem('token', data.token)
                }

                this.$router.replace({ name: 'index' })
            }).catch(err => { })
        }
    }
};
</script>

<style lang="less" scoped>
#login {
    width: 100vw;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .logo-and-title {
        display: flex;
        align-items: center;

        .logo {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            margin-right: 20px;
        }

        .title {
            font-size: 28px;
            line-height: 28px;
        }
    }

    .tips {
        margin: 30px;
        font-size: 16px;
        color: #999;
    }

    .input-item {
        width: 300px;
    }

    .remember-login {
        padding: 0 0 20px;
    }

    .login-btn {
        width: 300px;
    }
}
</style>